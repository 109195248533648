<template>
  <div>
   
    <section v-if="header == 'home'" class="header-anasayfa">
      <div id="ust-bolum">
        <div class="logo">
          <router-link to="/">
            <img src="../../../public/assets/img/logo.png"/>
          </router-link>
        </div>

        <div id="lang_selector" :class="{ 'language-dropdown': true, 'open': dilIsOpen }" @click="dilChange">
          <template  v-for="item in diller" >
            <label for="toggle" class="lang-flag"   v-if="selectedLand == item.kisaltma">
              <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
            </label>
          </template>
          <ul class="lang-list">
              <li  v-for="item in diller" :class="{ 'lang': true, 'selected': selectedLand === item.kisaltma }" :title="item.kisaltma"  @click="dilChangeFunction(item.kisaltma)">
                <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
              </li>
          </ul>
        </div>

        <label class="mMenu" for="check">
          <input  @click="isActive=!isActive"  type="checkbox" id="check" :data-c="isActive"/>
          <span></span>
          <span></span>
          <span></span>
        </label>
        <div class="mMenuContent " @click="isActive=!isActive"  :class="[isActive ? 'acc' : '']">
          <div class="mMenuPart">
            <ul>
              <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to="/urunler/cikolata">{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
            </ul>
          </div>
        </div>

        <div class="menu">
          <ul>
            <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to="/urunler/cikolata">{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
        
          </ul>
        </div>
        <img src="../../../public/assets/img/1974.png" id="since"/>
        <div class="sosyal-1">
          <ul>
            <li><a :href="social.facebook" target="_blank"><i class="fab fa-facebook-square"></i></a></li>
            <li><a :href="social.instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
            <li><a :href="social.youtube" target="_blank"><i class="fab fa-youtube"></i></a></li>
            <li><a :href="social.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a></li>
          </ul>
        </div>
      </div>
      <img src="../../../public/assets/img/header-bg.png"/>
    </section>

    <section v-if="header == 'default'"  class="header-2">
      <div id="ust-bolum">
        <div class="logo">
          <router-link to="/">
            <img src="../../../public/assets/img/logo.png"/>
          </router-link>
        </div>
        <div id="lang_selector" :class="{ 'language-dropdown': true, 'open': dilIsOpen }" @click="dilChange">
          <template  v-for="item in diller" >
            <label for="toggle" class="lang-flag"   v-if="selectedLand == item.kisaltma">
              <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
            </label>
          </template>
          <ul class="lang-list">
              <li  v-for="item in diller" :class="{ 'lang': true, 'selected': selectedLand === item.kisaltma }" :title="item.kisaltma"  @click="dilChangeFunction(item.kisaltma)">
                <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
              </li>
          </ul>
        </div>
        <label class="mMenu" for="check">
          <input  @click="isActive=!isActive"  type="checkbox" id="check" :data-c="isActive"/>
          <span></span>
          <span></span>
          <span></span>
        </label>
        <div class="mMenuContent " @click="isActive=!isActive"  :class="[isActive ? 'acc' : '']">
          <div class="mMenuPart">
            <ul>
              <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to="/urunler/cikolata">{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="menu">
          <ul>
            <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to="/urunler/cikolata">{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
          </ul>
        </div>

      </div>

      <img src="../../../public/assets/img/header-bg-2.png"/>
    </section>

    <section v-if="header == 'custom'"  class="header-2">
      <div id="ust-bolum">
        <div class="logo">
          <router-link to="/">
            <img src="../../../public/assets/img/logo.png"/>
          </router-link>
        </div>

        <div id="lang_selector" :class="{ 'language-dropdown': true, 'open': dilIsOpen }" @click="dilChange">
          <template  v-for="item in diller" >
            <label for="toggle" class="lang-flag"   v-if="selectedLand == item.kisaltma">
              <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
            </label>
          </template>
          <ul class="lang-list">
              <li  v-for="item in diller" :class="{ 'lang': true, 'selected': selectedLand === item.kisaltma }" :title="item.kisaltma"  @click="dilChangeFunction(item.kisaltma)">
                <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
              </li>
          </ul>
        </div>

        <label class="mMenu" for="check">
          <input  @click="isActive=!isActive"  type="checkbox" id="check" :data-c="isActive"/>
          <span></span>
          <span></span>
          <span></span>
        </label>
        <div class="mMenuContent " @click="isActive=!isActive"  :class="[isActive ? 'acc' : '']">
          <div class="mMenuPart">
            <ul>
              <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to="/urunler/cikolata">{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="menu">
          <ul>
            <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to="/urunler/cikolata">{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
          </ul>
        </div>

      </div>

      <img src="../../../public/assets/img/header-bg-3.png"/>
    </section>

    <section v-if="header == 'prod'"  class="header-2">
      <div id="ust-bolum">
        <div class="logo">
          <router-link to="/">
            <img src="../../../public/assets/img/logo.png"/>
          </router-link>
        </div>

        <div id="lang_selector" :class="{ 'language-dropdown': true, 'open': dilIsOpen }" @click="dilChange">
          <template  v-for="item in diller" >
            <label for="toggle" class="lang-flag"   v-if="selectedLand == item.kisaltma">
              <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
            </label>
          </template>
          <ul class="lang-list">
              <li  v-for="item in diller" :class="{ 'lang': true, 'selected': selectedLand === item.kisaltma }" :title="item.kisaltma"  @click="dilChangeFunction(item.kisaltma)">
                <span class="flag" :style='{ backgroundImage: `url(${item.icon})` }'></span>
              </li>
          </ul>
        </div>

        <label class="mMenu" for="check">
          <input  @click="isActive=!isActive"  type="checkbox" id="check" :data-c="isActive"/>
          <span></span>
          <span></span>
          <span></span>
        </label>
        <div class="mMenuContent " @click="isActive=!isActive"  :class="[isActive ? 'acc' : '']">
          <div class="mMenuPart">
            <ul>
              <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to>{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="menu">
          <ul>
            <li><router-link to="/">{{lang.anasayfa }}</router-link></li>
              <li><router-link to="/kurumsal">{{lang.kurumsal }}</router-link></li>
              <li><router-link to>{{lang.urunler }}</router-link></li>
              <li><router-link to="/duyurular">{{lang.duyurular }}</router-link></li>
              <li><router-link to="/iletisim">{{lang.iletisim }}</router-link></li>
          </ul>
        </div>

      </div>

      <img src="../../../public/assets/img/header-bg-3.png"/>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props:['header'],
  data(){
    return{
      isActive: false,
      social: [],
      lang: [],
      diller: [],
      dilIsOpen: false,
      selectedLand:'tr',
    }
  },
  mounted() {
 
    if (!localStorage.lang) {
      localStorage.lang='tr';
      this.selectedLand=localStorage.lang;
    }
 
},
  created() {
    this.selectedLand=localStorage.lang;
    axios.get('https://yonet.gifadwork.com/api/tr/gesas/instagram')
        .then(response => {
          this.social = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
      axios.get('https://yonet.gifadwork.com/api/'+localStorage.lang+'/gesas/langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
      axios.get('https://yonet.gifadwork.com/api/tr/gesas/diller')
        .then(response => {
          this.diller = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
        dilChange:function () {
            this.dilIsOpen = !this.dilIsOpen;
          },
        dilChangeFunction:function (lang) {
            localStorage.setItem('lang', lang);
            this.selectedLand=lang;
            location.reload(); 
          }
        },
        
}
</script>
<style scoped>
.mMenuContent{
  height: 100vh;
  width: 100vw;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  transform: translateX(120%);
  transition: all .5s ease-in-out;

}
.mMenuContent.acc{
  transform: translateX(0);
  transition: all .8s ease-in-out;

}
.mMenuContent .mMenuPart{
  position: relative;
  background: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}
.mMenuContent .mMenuPart:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 200vw;
  height: 100vh;
  background: #89c93b;
  transform: translateX(-5%);
  z-index: -1;
}
.mMenuContent .mMenuPart:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 200vw;
  height: 100vh;
  background: #af1116;
  transform: translateX(-10%);
  z-index: -2;
}


.mMenuContent .mMenuPart ul{
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.mMenuContent .mMenuPart ul li{
  margin: 10px;
}
.mMenuContent .mMenuPart ul li>*{
  font-size: 30px!important;
  text-decoration: none;
  color: red;
  margin: 20px;
}
label.mMenu{
  position: absolute;
  right: 10px;
  display:none;
  flex-direction:column;
  width:70px;
  cursor:pointer;
  transform: scale(.4);
  z-index: 998;
}

label.mMenu span{
  background: #fff;
  border-radius:10px;
  height:7px;
  margin: 7px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


label.mMenu span:nth-of-type(1){
  width:50%;

}

label.mMenu span:nth-of-type(2){
  width:100%;
}

label.mMenu span:nth-of-type(3){
  width:75%;

}


label.mMenu input[type="checkbox"]{
  display:none;
}
label.mMenu input[type="checkbox"] ~ span{
  background: #6eb01f;
}
label.mMenu:after{
  content: '';
  width: 100px;
  height: 100px;
  background: #fff;
  position: absolute;
  top: -30%;
  left: -30%;
  z-index: -1;
  border-radius: 50%;
}
label.mMenu input[data-c="true"] ~ span{
  background: #af1116;
}



label.mMenu input[data-c="true"] ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(8px,0px)
}


label.mMenu input[data-c="true"] ~ span:nth-of-type(2){

  transform-origin:top;
  transform:rotatez(-45deg)
}


label.mMenu input[data-c="true"] ~ span:nth-of-type(3){

  transform-origin:bottom;
  width:50%;
  transform: translate(30px,-11px) rotatez(45deg);

}
@media (max-width: 860px){
  label.mMenu,
  .mMenuContent{

    display:flex;
  }
  }
</style>