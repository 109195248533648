<template>
  <div class="footerShape"></div>

  <section class="footer">

    <img id="footerbg"  src="../../../public/assets/img/logo.png"/>
    <div class="footerMenu">
      <router-link to="/belgeler" >{{lang.belgeler }}</router-link>
      <router-link to="/urunTalep" >{{lang.uruntalep }}</router-link>
      <router-link to="/insanKaynaklari" >{{lang.insankaynaklari }}</router-link>
    </div>
    <div class="sosyal-footer">
      <a :href="social.facebook" target="_blank"><i class="fab fa-facebook-square"></i></a>
      <a :href="social.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
      <a :href="social.youtube" target="_blank"><i class="fab fa-youtube"></i></a>
      <a :href="social.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
    </div>
    <div class="footerCopy">{{lang.copyright }} Design by <a href="//gifadwork.com" target="_blank" style="color: #fff;font-weight:700;text-decoration: none">Gif Adw.</a></div>


  </section>
</template>
<script>
import axios from "axios";
export default {
  data(){
    return{
      social:[],
      lang:[],
    }
  },
  created() {
    axios.get('https://yonet.gifadwork.com/api/'+localStorage.lang+'/gesas/langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('https://yonet.gifadwork.com/api/tr/gesas/instagram')
        .then(response => {
          this.social = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods:{
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  watch:{
    $route (to, from){
      console.clear();
      setTimeout(function(){
        console.clear();
        console.log(
            '                                   ######\n' +
            '                                ############\n' +
            '                               ############\n' +
            '                               #######\n' +
            '   ##############   ####### #############\n' +
            ' #################  ####### ##############\n' +
            '##################          ############\n' +
            '#######    #######  #######    #######\n' +
            '#######    #######  #######    #######\n' +
            '##################  #######    #######\n' +
            ' #################  #######    #######\n' +
            '   ###############  #######    #######\n' +
            '          ########  #######    #######\n' +
            ' ################\n' +
            ' ##############\n' +
            '    ########')}, 1000);
    }
  }
}
</script>
<style scoped>
#footerbg{
  max-width: 300px;
  width: 25vw;
  min-width: 150px;
  position: relative;
  z-index: 99;
}
.footerShape{
  height: 110px;
  position: relative;
  bottom: -80px;
  width: 100%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background: rgb(161,15,19);
  background: -moz-linear-gradient(100deg, rgba(161,15,19,1) 0%, rgba(255,0,8,1) 100%);
  background: -webkit-linear-gradient(100deg, rgba(161,15,19,1) 0%, rgba(255,0,8,1) 100%);
  background: linear-gradient(100deg, rgba(161,15,19,1) 0%, rgba(255,0,8,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a10f13",endColorstr="#ff0008",GradientType=1);

}
.footer{
  padding-bottom: 20px;
  text-align: center;
  position:relative;
  width: 100%;
  background: rgb(161,15,19);
  background: -moz-linear-gradient(100deg, rgba(161,15,19,1) 0%, rgba(255,0,8,1) 100%);
  background: -webkit-linear-gradient(100deg, rgba(161,15,19,1) 0%, rgba(255,0,8,1) 100%);
  background: linear-gradient(100deg, rgba(161,15,19,1) 0%, rgba(255,0,8,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a10f13",endColorstr="#ff0008",GradientType=1);
}
.footerMenu{
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  grid-gap: 30px;
  margin-top: 20px;
}
.footerMenu a{
  color: white;
  text-decoration:none;
}
.footerMenu a:hover{
   text-decoration:underline;
 }
.footerCopy{
  display: block;
  margin-top: 20px;
  color: white;
}
.sosyal-footer{
  margin-top: 20px;

}
.sosyal-footer a{
  text-decoration: none;
  color:white;
  margin-left: 4px;
  font-size: 20px;
}

</style>