<template>
  <div>

    <section class="urun-anasayfa">
      <div id="buyuk-urun">
        <div id="ust" data-aos="fade-up">
          <div id="buyuk-urun-foto">
            <scroll-parallax :right="true"  direction="x">
              <img src="../../../public/assets/img/anasayfa-urun-buyuk.png" style="max-width: 50vw"/>
            </scroll-parallax>
          </div>
          <div id="buyuk-urun-aciklama">
            <h3>{{lang.gesas }}</h3>
            <h2>{{lang.cikolata }}</h2>
<!--            <p></p>-->
            <br>
<!--            <router-link to="/iletisim" class="btn whiteAq"><span>İletişim</span></router-link>-->
            <router-link to="/urunler/cikolata" class="btn greenWhite right"><span>{{lang.cikolata }}</span></router-link>
          </div>
        </div>
        <img id="bez" src="../../../public/assets/img/anasayfa-urun-bg-bez.png" width="100%" height="auto" />
        <div class="bgHolder">
          <img src="../../../public/assets/img/anasayfa-urun-bg-big.png" width="100%" height="auto" />
        </div>
      </div>
      <div id="diger-urunler">
        <div id="urunler" class="mobilde-gizle">
          <div class="urun" v-for="(item,index) in categories">
            <div class="urun-foto">
              <scroll-parallax direction="y"
                               :down="false"
                               :up="true"
                               :speed="0.03">

                <img :src="item.img" class="spfix1" :alt="item.heading">
              </scroll-parallax>
              <router-link :to="'/urunler/'+item.seo" class="btn greenWhite"><span>{{ item.heading }}</span></router-link>
            </div>
          </div>
        </div>
        <div class="bgHolder v2">
        <img src="../../../public/assets/img/anasayfa-urun-bg-alt.png" width="100%" height="auto" />
         </div>
      </div>
    </section>

    <section class="duyurular-anasayfa">
      <div id="aciklama">
        <h3>{{lang.duyurularve }}</h3>
        <h2>{{lang.bizdenhaberler }}</h2>
        <br>
        <router-link to="/duyurular" class="btn whiteRed left"><span>{{lang.tumhaberler }}</span></router-link>
      </div>
      <div id="duyurular">
        <router-link :to="'/duyuru/'+item.seo" class="duyuru" v-for="item in news.slice(0, 2)">
          <div id="foto">
            <img :src="item.img" :alt="item.heading">
          </div>
          <div id="cart">
            <h3>{{ item.heading }}</h3>
          </div>
        </router-link>
      </div>

      <img id="bgcizgi" src="../../../public/assets/img/kirmizi-cizgi.png"/>

    </section>
    <section class="instagram-anasayfa">
      <div id="fotolar">
        <div v-for="(item, index) in social.instaimg" :class="(index > 1) ? (index > 2)? 'foto tablette-gizle mobilde-gizle':'foto mobilde-gizle' :'foto'"><img :src="item" alt=""></div>
      </div>
      <a :href="social.instagram" target="_blank" class="btn greenWhite v2"><span><i class="fab fa-instagram"></i>{{lang.bizitakipedin }}</span></a>
      <img id="instabg" src="../../../public/assets/img/insta-bg.png" width="100%" height="auto" />
    </section>
  </div>
</template>

<script>
import iso from "../../axios";
import axios from "axios";
export default {
  data(){
    return{
      news:[],
      cc:[],
      categories:[],
      social:[],
      lang:[],
    }
  },
  created() {
    iso.get('news')
        .then(response => {
          this.news = response.data
          console.log(response.data)
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('categories')
        .then(response => {
          for (let i = 0; i < 5; i++ ){
            this.cc.push(response.data[i])
            if(i <= 3){
              this.categories.push(response.data[i])
            }else{
              this.acc = response.data[i]
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    
        axios.get('https://yonet.gifadwork.com/api/'+localStorage.lang+'/gesas/langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('https://yonet.gifadwork.com/api/tr/gesas/instagram')
    .then(response => {
        this.social = response.data[0]
      })
          .catch(error => {
            console.log(error);
          })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>

</style>
