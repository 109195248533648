import { createApp } from 'vue'
import App from './App.vue'
import VueLazyload from '@jambonn/vue-lazyload'
import router from './router'
import {Axios} from "axios";
import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue'

const app = createApp(App)
app.use(router, VueLazyload, Axios, AOS.init({ disable: (window.innerWidth < 768) ? 'true' : 'false' }))
app.component('scroll-parallax',ScrollParallax)

// or with options
const loadimage = require('../public/assets/loader.gif')
const errorimage = require('../public/assets/error.gif')
app.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1
})

app.mount('#app')