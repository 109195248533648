import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home,
    meta: {
      header: 'home',
    }
  },
  {
    path: '/kurumsal',
    name: 'Kurumsal',
    component: () => import('../components/views/About.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/urunler',
    name: 'Ürünler',
    component: () => import('../components/views/Products.vue'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/urunler/:cat',
    name: 'Ürünler ',
    component: () => import('../components/views/Products.vue'),
    meta: {
      header: 'prod'
    }
  },
  {
    path: '/urun/:product',
    name: 'Ürün',
    component: () => import('../components/views/ProductDetail.vue'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/duyurular',
    name: 'Duyurular',
    component: () => import('../components/views/News.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/duyuru/:news',
    name: 'Duyuru',
    component: () => import('../components/views/NewsDetail.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/belgeler',
    name: 'Belgeler',
    component: () => import('../components/views/Docs.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/insanKaynaklari',
    name: 'İnsan Kaynakları',
    component: () => import('../components/views/HumanResources.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/urunTalep',
    name: 'Ürün Talep',
    component: () => import('../components/views/ProductOffer.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: () => import('../components/views/Contact.vue'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/yonet',
    name: 'Admin',
    component: () => import('../components/views/Admin.vue'),
    meta: {
      header: 'default'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next)=>{
  document.title = 'Gesaş | '+to.name
  next()
})
export default router

